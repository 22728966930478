.vjs-custom-waiting .vjs-loading-spinner
{
    display: block;
}
.video-js.vjs-custom-waiting .vjs-loading-spinner:before,
.video-js.vjs-custom-waiting .vjs-loading-spinner:after
{
    /* I just copied the same animation as in the default css file */
    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
} 

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
      overflow: hidden;
      opacity: 0.3;
      border-radius: 30px;
      background-color: #eee;
      -webkit-appearance: none;
    }

    input[type='range']:hover{
      opacity: 1;
    }
    
    input[type='range']::-webkit-slider-runnable-track {
      height: 20px;
      -webkit-appearance: none;
      color: rgb(245, 0, 87);
      margin-top: -1px;
    }

    input[type='range']::-webkit-slider-runnable-track:hover  {
      height: 30px;
    }
    
    input[type='range']::-webkit-slider-thumb {
      width: 30px;
      -webkit-appearance: none;
      height: 100%;
      cursor: ew-resize;
      background: rgb(245, 0, 87);
    }

}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: rgb(245, 0, 87); 
}
input[type="range"]::-moz-range-track {  
  background-color: #eee;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: rgb(245, 0, 87); 
}
input[type="range"]::-ms-fill-upper {  
  background-color: #eee;
}