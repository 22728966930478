@font-face 
{
    font-family: 'montserrat-medium';
    src: local('montserrat-medium'), url(../Resources/fonts/Montserrat-Medium.ttf) format('truetype');
}
/*  Overlay Style*/

.overlay {
    font-family: 'montserrat-medium';
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000000000000000000000;
    top: 0;
    left: 0;

    background-color: rgb(0,0,0);
    background:rgb(31 42 67) !important;
    overflow-x: hidden;
    transition: 0.5s;
  }
  
.overlaycontent {
    position: relative;
    top: 5%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
.overlay li{
    padding: 8px;
    font-size:36px;
    display: block;
    transition: 0.3s;
  }
.overlay a{
    text-decoration: none!important;
  }
  
.overlay .closebtn {
    color: white;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

.hamburger
{
cursor: pointer;
color:black;
font-size: 30px;
}

  /*Overlay Styling ends*/


.Container
{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows:50px auto;
    min-height: 100vh;
    width: 100vw;
    background-color: rgb(17,24,39);
    font-family:'montserrat-medium';
}


.navbarcontainer
{
    width: 100%;
    height: 80px;
    z-index: 10000;
    grid-column: auto/span 12;
    position: fixed;
    justify-content: space-evenly;
    align-items: center;
    color:white;
    padding: 10px;
    background-color:rgb(17,24,39);
    -webkit-box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
    box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
}

.logocol a
{   display: flex;
    align-items:center;
    text-decoration: none!important;
    list-style:none;  
    color:black;
    outline:none;
    padding-top:5px;
    padding-bottom: 5px;
}
.logocol>a>span{
    margin:5px;
}
   

.navlist ul
{
    display: flex;
    flex-direction: row;
    margin:0 auto; /*clears bottom-margin UL items*/
}

.navlist ul li
{
    list-style-type:none;
    margin-right:10px;
}

.navlist a
{
    font-size:14px;
    text-transform:uppercase;
    border-bottom:2px solid rgb(31 42 67);
    color:black;
    padding:5px 10px;
    text-decoration:none!important;
    border-radius:20px;
    outline:none;
}


.contentcontainer{
    grid-column: auto /span 12;
    display: grid;
    margin-top: 60px;
}

.contentcontainer2{
    grid-column: auto /span 12;
    display: grid;
    height: 100vh;
}


/*Responsive*/
@media(max-height:600px){
    .heading{
        font-size: 30px;
    }
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
}
@media(max-width:767px){
    .navlist{
        display:none;
    }
   .navbarcontainer{
       justify-content: space-between;
   }
}