.contentwrapper{
    display: grid;
    align-self: center;
    justify-self: center;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows:auto;
}

.emailbox {
    padding: 5px;
    width: 100%;
}
.mainrow{
    align-items: center;
}

.firstcol{
grid-column: auto/span 6;
}

.secondcol{
    grid-column: auto/span 5;
}

.stock_illustration{
    max-width: 100%;   
}
.heading{
    color:#5efce8;
}



.navrow a{
    border:2px solid #5EFCE8;
    color:black;
    padding:5px 10px;
    text-decoration: none!important;
    border-radius:20px;
}

.navbarcontainer{
    text-align: start;
    color:white;
    padding: 10px;
    background-color:#fff;
    -webkit-box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
    box-shadow: -1px 27px 26px -20px rgba(0,0,0,0.75);
}


.navbar{

}
.paralogin{
margin-top:20px;
font-size:10px;
}
.signup a{
}

        /*Responsive*/
@media(max-height:600px)
{
    .heading{
        font-size: 30px;
    }
    .mainrow{
        margin-top:30px;
    }
    .para{
        font-size: 12px;
    }
}
@media(max-width:767px)
{
    .firstcol
    {
        grid-column: auto/span 12;
    }
        
    .secondcol
    {
        grid-column: 2/span 10;
    }
}